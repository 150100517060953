import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';
import ademuyiwa from '../../assets/images/departments/surgery/ademuyiwa.png';
import adesanya from '../../assets/images/departments/surgery/adesanya.png';
import afolayan from '../../assets/images/departments/surgery/afolayan.png';
import atoyebi from '../../assets/images/departments/surgery/atoyebi.png';
import balogun from '../../assets/images/departments/surgery/balogun.png';
import bankole from '../../assets/images/departments/surgery/bankole.png';
import elebute from '../../assets/images/departments/surgery/elebute.png';
import bode from '../../assets/images/departments/surgery/bode.png';
import ayomide from '../../assets/images/departments/surgery/ayomide.png';
import felix from '../../assets/images/departments/surgery/felix.png';
import jegede from '../../assets/images/departments/surgery/jegede.png';
import jeje from '../../assets/images/departments/surgery/jeje.png';
import jones from '../../assets/images/departments/surgery/jones.png';
import justin from '../../assets/images/departments/surgery/justin.png';
import kanu from '../../assets/images/departments/surgery/kanu.png';
import mofikoya from '../../assets/images/departments/surgery/mofikoya.png';
import obayemi from '../../assets/images/departments/surgery/obayemi.png';
import ogunjimi from '../../assets/images/departments/surgery/ogunjimi.png';
import ogunleye from '../../assets/images/departments/surgery/ogunleye.png';
import ojewola from '../../assets/images/departments/surgery/ojewola.png';
import ojo from '../../assets/images/departments/surgery/ojo.png';
import olajide from '../../assets/images/departments/surgery/olajide.png';
import olusoji from '../../assets/images/departments/surgery/olusoji.png';
import oluwaseun from '../../assets/images/departments/surgery/oluwaseun.png';
import onafadeji from '../../assets/images/departments/surgery/onafadeji.png';
import orimi from '../../assets/images/departments/surgery/orimi.png';
import osinowo from '../../assets/images/departments/surgery/osinowo.png';
import tijani from '../../assets/images/departments/surgery/tijani.png';
import ugburo from '../../assets/images/departments/surgery/ugburo.png';

const Surgery = () => {
  const services = [
    {
      name: 'Minimal Access Surgery',
    },
    {
      name: 'Laparoscopic Surgery',
    },
    {
      name: 'Endoscopic Surgery',
    },
    {
      name: 'Day Case Surgery',
    },
    {
      name:
        'Trauma Surgery',
    },
    {
      name:
        'Cancer Surgery',
    }
  ];

  return (
    <Layout pageTitle='Department of Surgery | LUTH'>
      <NavOne />
      <PageHeader title='Department of Surgery' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Surgery
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The department of surgery of the Lagos University
                        Teaching Hospital is one of the founding departments of
                        the hospital, which started when the hospital was
                        created in 1961. The department expanded over the years
                        with subspecialties introduction in 1982. Departments of
                        Ophthalmology, Otorhinolaryngology and more recently,
                        Orthopaedics and traumatology were created from the
                        department of surgery. Presently there are 6 major units
                        (subspecialties) in the department. However, some units
                        are further subdivided into two teams. These units (and
                        teams) are: <br />
                        1. General surgery (Teams A&B)
                        <br /> 2. Paediatric surgery
                        <br />
                        3. Urology (Teams A & B)
                        <br />
                        4. Cardiothoracic and vascular surgery
                        <br />
                        5. Burns and plastic surgery
                        <br />
                        6. Neurosurgery
                        <br />
                        <br />
                        Each unit are sub-divided into sub-units. This is to
                        ensure proficiency in surgical skills development and
                        management of patients presenting to the hospital.
                      </p>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                        Vision Statement
                      </h2>
                      To be a foremost university department of surgery in a
                      tertiary hospital, aspiring to achieve national and
                      international reputation for excellence in teaching,
                      research and patient care.
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                        Mission Statement
                      </h2>
                      1. To provide comprehensive and globally relevant
                      undergraduate and postgraduate medical education in
                      surgery <br />
                      2. To facilitate the performance of research projects
                      especially those that improves the outcome of surgery{' '}
                      <br />
                      3. To provide the highest quality of care for patients
                      with surgical disorders in a tertiary health institution.
                    </Spoiler>
                  </div>
                  <div label='Services'>
                  <p className='course-details__tab-text' style={{ marginBottom: 10 }}>
                        The Department of Surgery offers services in:
                      </p>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={atoyebi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor O.A.Atoyebi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Head of Department, Consultant General Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS (Honours),FMCS, FWACS, MD, FNAMed
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adesanya} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor A.A. ADESANYA</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Professor/Consultant General Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MSc, FMCS, FWACS, FICS, FACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={bode} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>PROFESSOR C.O. BODE</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Professor/Consultant Paediatric Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FMCS, FWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ademuyiwa} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>PROFESSOR A.O. ADEMUYIWA</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Professor/Consultant Paediatric Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MD, FWACS, FMCS (Paeds), FMCEM, FACS, FNAMed
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ugburo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>PROFESSOR O.A. UGBURO</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Professor/ Consultant Burns and Plastic Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={tijani} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>PROFESSOR K.H. TIJANI</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Professor/ Consultant Urological Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={jeje} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. E.A. JEJE </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Associate Professor/ Consultant Urological Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BSc. MBChB, FMCS, FWACS, Cert. Urology (UK), MD
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={mofikoya} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. B.O. MOFIKOYA </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Associate Professor / Consultant Burns and Plastic
                            Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FMCS, FWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={bankole} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. O.B. BANKOLE </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Associate Professor / Consultant Neurosurgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={kanu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. O.O. KANU </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Associate Professor / Consultant Neurosurgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, Cert Neurosurgery, FWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ogunleye} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. E.O. OGUNLEYE </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/ Consultant Cardiothoracic and
                            vascular Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olajide} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. T.O. OLAJIDE </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/ Consultant General Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ojewola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. R.W. OJEWOLA</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer /Consultant Urological Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBChB, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={osinowo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. A. O. Osinowo </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer / Consultant General Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FMCS, FMAS (India), DMAS (India) FWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olusoji} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. O.O. OLUSOJI</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/ Consultant Cardiothoracic and
                            Vascular Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ojo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. O.A. OJO</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer /Consultant Neurosurgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MRCSEd, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={elebute} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. O.A. ELEBUTE</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer/ Consultant Paediatric Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBChB, FWACS, MBA, FACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ogunjimi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. M.A. OGUNJIMI</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer / Consultant Urological Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCS, FACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={balogun} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. O.S. BALOGUN</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer /Consultant General Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={afolayan} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. M.O. AFOLAYAN </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Lecturer /Consultant General Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FMCS, FWACS, FMAS, DMAS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={justin} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Justina O. Seyi-Olajide </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Paediatric Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oluwaseun} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oluwaseun A. Ladipo-Ajayi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Paediatric Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={felix} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Felix M. Alakaloko</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Paediatric Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBChB, FMCS, FMAS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={orimi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Orimisan Belie</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant burns and plastic surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBChB, MWACS, FMCS
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ayomide} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ayomide Makanjuola</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant General Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FMCS.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={obayemi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>OBAYEMI KUDIRAT BOLA </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            ASST. CHIEF CONF.SECRETARY
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            WASC, - RSA (STAGES 1,2,&3)IN TYPEWRITING, - ND
                            SECRETARIAL STUDIES - HND SECRETARIAL STUDIES
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={jones} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>JONES-OBIAZU FAITH EGHOM</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            SENIOR CONF. SECRETARY
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            SSCE, - OND SECRETARIAL STUDIES - HND SECRETARIAL
                            STUDIES
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={jegede} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>JEGEDE ALBERT KOLAWOLE</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            CHIEF CLERICAL OFFICER
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            WASSC, - BSc. BUSINESS ADMINISTRATION, - MBA
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={onafadeji} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>ONAFADEJI	ADEDAYO	ADEWALE</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                           CLERICAL OFFICER 1
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            WASSC
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:surgery@luth.gov.ng'>                        
                        surgery@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Surgery;
